@keyframes shine { 
    0% {
        opacity: .2;
    }
    50% {
        opacity: 1;  
    }
    100% {
        opacity: .2;
    }
}

.shine-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;
    margin: 0;  /* Remove any margins */
    padding: 0; /* Remove any padding */
    box-sizing: border-box; /* Ensure all elements are sized correctly */
}

.shine-container {
    text-align: center;
}



.shine-item h3 {
    margin-bottom: 10px;
    color: #4f5160;
}

#shine {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .shine-item {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .shine-item {
        width: 100%;
    }
}
