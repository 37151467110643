/* Breadcrumbs.css */

/* Container Styling */
.breadcrumb-nav {
  padding: 10px 20px;
  background-color: #f8f9fa;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.breadcrumb-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  margin: 0 8px;
  color: #6c757d;
}

.breadcrumb-link {
  display: flex;
  align-items: center;
  color: #6b6b6c;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.2s ease;
}

.breadcrumb-link:hover {
  color: #0056b3;
}

.breadcrumb-link i {
  margin-right: 5px;
}

.breadcrumb-active {
  color: #6c757d;
  pointer-events: none;
}

@media (max-width: 768px) {
  .breadcrumb-nav {
    padding: 8px 15px;
  }

  .breadcrumb-link {
    font-size: 0.9rem;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    margin: 0 5px;
  }
}

@media (max-width: 576px) {
  .breadcrumb-list {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .breadcrumb-item {
    flex: none;
  }
}
